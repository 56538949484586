import { convertToCpf } from '../../common/functions/helpers';

export default class Usuario {
  constructor(data = null, tipo = 'FUNCIONARIO') {
    if (data) {
      this.id = data.id;
      this.tipo = data.tipo;
      this.idPerfil = data.idPerfil;
      this.nomPerfil = data.nomPerfil;
      this.indRecebeMail = data.indRecebeMail;
      this.indAtivo = data.indAtivo;
      this.indUsuarioExternoMaster = data.indUsuarioExternoMaster;
      this.idEstruturaUsuario = data.idEstruturaUsuario;
      this.nomEstruturaUsuario = data.nomEstruturaUsuario;
      this.idGrupoFornecedor = data.idGrupoFornecedor;
      this.nomGrupoFornecedor = data.nomGrupoFornecedor;
      this.indAprovaWorkflow = data.indAprovaWorkflow;
      this.indProcuradorVigenciaExpirada = data.indProcuradorVigenciaExpirada;
      this.nomUsuario = data.nomUsuario;
      this.nome = data.nome;
      this.nomeSobrenome = data.nomeSobrenome;
      this.sobrenome = data.sobrenome;
      this.email = data.email;
      this.matricula = data.matricula;
      this.idExterno = data.idExterno;
      this.cpf = data.cpf;
      this.indProcurador = data.indProcurador;
      this.dtaInicioVigencia = data.dtaInicioVigencia;
      this.dtaFimVigencia = data.dtaFimVigencia;

      this.idDivisoes = data.idDivisoes;
      this.superioresImediatos = data.superioresImediatos;
      this.clientes = data.clientes;
      this.tiposVerba = data.tiposVerba;
      this.desAtributos = Usuario.montarDesAtributos(data);

      if (data.tipo === 'FORNECEDOR') {
        this.indRepresentacaoConjunto = data.indRepresentacaoConjunto;
        this.indAprovadorAnexoComercial = data.indAprovadorAnexoComercial;
      }
    } else {
      this.tipo = tipo;
      this.indRecebeMail = true;
      this.indAprovaWorkflow = false;
      this.indAtivo = true;
      this.idDivisoes = [];
      this.superioresImediatos = [];
      this.clientes = [];
      this.tiposVerba = [];
      this.canais = [];
      this.documento = null;
      this.indRepresentacaoConjunto = false;
      this.indAprovadorAnexoComercial = false;
    }
  }

  construirAPartirDaConsultaCompleta(dadosDaConsulta) {
    this.id = dadosDaConsulta.id;
    this.tipo = dadosDaConsulta.tipo;
    this.idPerfil = dadosDaConsulta.idPerfil;
    this.nomPerfil = dadosDaConsulta.nomPerfil;
    this.indRecebeMail = dadosDaConsulta.indRecebeMail;
    this.indAtivo = dadosDaConsulta.indAtivo;
    this.indUsuarioExternoMaster = dadosDaConsulta.indUsuarioExternoMaster;
    this.idEstruturaUsuario = dadosDaConsulta.idEstruturaUsuario;
    this.nomEstruturaUsuario = dadosDaConsulta.nomEstruturaUsuario;
    this.idGrupoFornecedor = dadosDaConsulta.idGrupoFornecedor;
    this.nomGrupoFornecedor = dadosDaConsulta.nomGrupoFornecedor;
    this.indAprovaWorkflow = dadosDaConsulta.indAprovaWorkflow;
    this.nomUsuario = dadosDaConsulta.nomUsuario;
    this.nome = dadosDaConsulta.nome;
    this.nomeSobrenome = dadosDaConsulta.nomeSobrenome;
    this.sobrenome = dadosDaConsulta.sobrenome;
    this.email = dadosDaConsulta.email;
    this.matricula = dadosDaConsulta.matricula;
    this.idExterno = dadosDaConsulta.idExterno;
    this.clientes = dadosDaConsulta.clientes;
    this.tiposVerba = dadosDaConsulta.tiposVerba;
    this.cpf = dadosDaConsulta.cpf;
    this.cargo = dadosDaConsulta.cargo;
    this.documento = dadosDaConsulta.documento;
    this.indProcurador = dadosDaConsulta.indProcurador;
    this.indProcuradorVigenciaExpirada = dadosDaConsulta.indProcuradorVigenciaExpirada;
    this.indAtivadoPeloAprovador = dadosDaConsulta.indAtivadoPeloAprovador;
    this.nom_perfil_aprovador = dadosDaConsulta.nom_perfil_aprovador;
    this.nom_usuario_aprovador = dadosDaConsulta.nom_usuario_aprovador;
    this.dtaInicioVigencia = dadosDaConsulta.dtaInicioVigencia;
    this.dtaFimVigencia = dadosDaConsulta.dtaFimVigencia;

    this.divisoes = Usuario.parsearCampos(dadosDaConsulta.objDivisoes, 'nome');
    this.idDivisoes = this.divisoes.map((item) => item.id);
    this.superioresImediatos = Usuario.parsearCampos(dadosDaConsulta.superioresImediatos, 'nomeCompleto');
    this.desAtributos = dadosDaConsulta.desAtributos;
    this.statusFluxo = dadosDaConsulta.statusFluxo;
    this.perfilAprovador = dadosDaConsulta.perfilUsuarioAprovacao;
    this.nomePerfilAprovador = dadosDaConsulta.nomePerfilAprovador;
    this.dtaInicioVigenciaPrevia = dadosDaConsulta.dtaInicioVigenciaPrevia;
    this.dtaFimVigenciaPrevia = dadosDaConsulta.dtaFimVigenciaPrevia;

    if (dadosDaConsulta.tipo === 'FORNECEDOR') {
      this.indAprovadorAnexoComercial = dadosDaConsulta.indAprovadorAnexoComercial;
      this.indRepresentacaoConjunto = dadosDaConsulta.indRepresentacaoConjunto;
    }
  }

  construirAPartirUsuarioOmni(usuarioOmni) {
    this.idExterno = usuarioOmni.id;
    this.nomUsuario = usuarioOmni.nomUsuario;
    this.nome = usuarioOmni.nome;
    this.sobrenome = usuarioOmni.sobrenome;
    this.cargo = usuarioOmni.cargo;
    if (this.tipo === 'FORNECEDOR') this.documento = usuarioOmni.documento;
    if (this.tipo === 'FUNCIONARIO') this.cpf = convertToCpf(usuarioOmni.documento);
    this.matricula = usuarioOmni.matricula;
    if (!usuarioOmni.indUsuarioExternoMaster) {
      this.indAtivo = usuarioOmni.indAtivo;
    } else {
      this.indAtivo = false;
    }
    this.indUsuarioExternoMaster = usuarioOmni.indUsuarioExternoMaster;
    this.indRecebeMail = usuarioOmni.indRecebeMail;
    const tenant = usuarioOmni.tenants.find((t) => t.indPrimeiro);
    if (tenant) {
      this.indProcurador = tenant.indProcurador;
      this.dtaInicioVigenciaPrevia = tenant.dtaInicioVigencia;
      this.dtaFimVigenciaPrevia = tenant.dtaFimVigencia;
      this.dtaInicioVigencia = tenant.dtaInicioVigencia;
      this.dtaFimVigencia = tenant.dtaFimVigencia;
    }
  }

  static parsearCampos(stringComAtributos, atributo) {
    if (!stringComAtributos) {
      return [];
    }

    return stringComAtributos.split(';;').map((umElementoEmString) => {
      const arrayComAtributos = umElementoEmString.split('|');
      const retorno = {};
      const [id, valor] = arrayComAtributos;
      retorno.id = parseInt(id, 10);
      retorno[atributo] = valor;
      return retorno;
    });
  }

  ehEdicao() {
    return !!this.id;
  }

  static montarDesAtributos(dados) {
    dados.desAtributos = dados.desAtributos
    && dados.desAtributos.value
      ? JSON.parse(dados.desAtributos.value) : {};

    if (!dados.desAtributos.regional) {
      return { regional: false };
    }

    return { ...dados.desAtributos };
  }
}
