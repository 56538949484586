<template>
    <v-dialog v-model="dialog" :persistent="true" max-width="40%">
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t('title.atencao') }}</span>
          </v-card-title>
          <v-card-text>
            <span>{{ $t('message.aviso_alteracao_data', { nome_perfil_aprovador_fluxo : this.nomePerfilAprovadorFluxo }) }}</span>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="cancelar()">
              {{ $t('label.cancelar') }}
            </v-btn>
            <v-btn
              @click="solicitarAprovacao()"
              class="mr-3"
              dark
              color="red">
              {{ $t('label.solicitar_aprovacao') }}
            </v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
  </template>

<script>
export default {
  name: 'UsuarioAprovacaoDialog',
  props: {
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    nomePerfilAprovadorFluxo: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    solicitarAprovacao() {
      this.$emit('SolicitarAprovacaoEdicaoData', true);
      this.close();
    },
    cancelar() {
      this.close();
      this.$emit('CancelarEdicaoData');
    },
  },
};
</script>
  <style lang="scss">
  .justify {
    text-align: justify;
  }
  </style>
